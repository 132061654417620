import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { REGULAR_PATH } from '../../../env';

class Email extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      resend: false,
      color: 'ui message info',
      success: false,
    };
  }

  verifyCode = () => {
    axios
      .get(`${REGULAR_PATH}/auth/email_verify?id=${this.props.match.params.id}`)
      .then((res) => {
        if (res.data.status === 'link expired') {
          this.setState({
            message: `Sorry, ${res.data.status}`,
            color: 'ui message error',
            resend: true,
            success: false,
          });
        } else if (res.data.status === 'already verified') {
          this.setState({
            message: 'Your account is already verified',
            color: 'ui message error',
            success: true,
          });
        } else {
          this.setState({
            message: 'Thank you, your email has been succesfully verified.',
            color: 'ui message success',
            success: true,
          });
        }
      })
      .catch((e) => {
        this.setState({
          message: 'Invalid Link',
          color: 'ui message error',
          success: false,
        });
      });
  };

  componentWillMount() {
    this.verifyCode();
  }

  sendEmail = () => {
    axios
      .get(
        `${REGULAR_PATH}/auth/send_email?resend=ok&email_id=${this.props.match.params.id}`,
      )
      .then((res) => {
        ('resending...');
      });
  };

  render() {
    return (
      <div className='ui two column centered grid'>
        <div className='column'>
          <Link to='/'>
            <img
              className='logo'
              src='/images/Logo-1.png'
              width='300'
              alt='Finder logo'
            />
          </Link>

          {this.state.message ? (
            <div className={this.state.color}>
              <b>{this.state.message}</b>
              <br />
              {/*
                      this.state.resend
                      ? <Link onClick={this.sendEmail} to={`/user/email/${this.props.routeParams.id}`}>Resend Email</Link>
                      : null
                      */}
              <br />
              {this.state.success ? (
                <Link to='/login'>Login with your account</Link>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default Email;
