import React from 'react';
import { Switch, Route, Redirect, Link } from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import './css/react-datetime.min.css';
import './css/app.css';

import LoginFlow from './components/login-flow';
import App from './components/app';
import UserProfile from './components/user/user-profile';
// import SignUp from './components/signUp/sign-up-page';
import Email from './components/signUp/email-verify';
import UserVerify from './components/signUp/user-verify';
import ForgetPassword from './components/signUp/forget-password';
import Login from './components/signUp/login';
import ResetPassword from './components/signUp/reset-password';
import IgnitionFleetLogin from './components/signUp/ignition-fleet-login';
import DashBoard from './components/dashboard';
import LiveShare from './components/LiveShare';
import TrackByCode from './components/TrackByCode';
import ACIAssets from './components/ACIAssets';
import VMS from './components/vms/VMS';
import Report from './components/reports/Reports';
import EmailReports from './components/reports/EmailReports';
import Geofence from './components/geofence/Geofence';
import DrawGeofence from './components/geofence/DrawGeofence';
import Syngenta from './components/Syngenta/Syngenta';
import MonthlyBill from './components/MonthlyBill/MonthlyBill';
import PaymentCancel from './components/MonthlyBill/PaymentCancel';
import PaymentFail from './components/MonthlyBill/PaymentFail';
import PaymentSuccess from './components/MonthlyBill/PaymentSuccess';
import MonthlyBillPdf from './components/MonthlyBill/MonthlyBillPdf';
import MonthlyBillRedirect from './components/MonthlyBill/MonthlyBillRedirect';
import AssetPanel from './components/runner/asset-panel';
import UserPanel from './components/runner/user-panel';
import ConnectAsset from './components/ConnectAsset';
import OilPoint from './components/oil-point';
import OilInfo from './components/oil-info';
import MobileStream from './components/mobile-stream/MobileStream';
import Temperature from './components/temperature/Temperature';

const authCheck = () => localStorage.getItem('user') !== null;

const AuthRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      authCheck() ? (
        <App>
          <Component {...props} />
        </App>
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location.pathname },
          }}
        />
      )
    }
  />
);

const LoggedInRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      !authCheck() ? (
        <LoginFlow>
          <Component {...props} />
        </LoginFlow>
      ) : (
        <Redirect to='/' />
      )
    }
  />
);

function NotFound() {
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>404 - Page Not Found</h1>
      <p>Sorry, the page you are looking for does not exist.</p>
      <Link to='/'>Back</Link>
    </div>
  );
}

export default function AppRouter() {
  return (
    <Switch>
      {/* Private Routes */}
      <AuthRoute exact path='/' component={DashBoard} />
      <AuthRoute exact path='/vms/:org' component={VMS} />
      <AuthRoute exact path='/email-reports' component={EmailReports} />
      <AuthRoute exact path='/live-share' component={LiveShare} />
      <AuthRoute exact path='/track-by-code' component={TrackByCode} />
      <AuthRoute exact path='/aci-assets' component={ACIAssets} />
      <AuthRoute exact path='/syngenta' component={Syngenta} />
      <AuthRoute exact path='/asset-panel' component={AssetPanel} />
      <AuthRoute exact path='/user-panel' component={UserPanel} />
      <AuthRoute exact path='/temperature' component={Temperature} />
      <AuthRoute exact path='/connect-asset' component={ConnectAsset} />
      <AuthRoute exact path='/profile' component={UserProfile} />
      <AuthRoute exact path='/all-reports' component={Report} />

      <AuthRoute exact path='/oil-point' component={OilPoint} />
      <AuthRoute exact path='/oil-info' component={OilInfo} />

      <AuthRoute exact path='/geofence' component={Geofence} />
      <AuthRoute exact path='/geofence/draw' component={DrawGeofence} />
      <AuthRoute exact path='/geofence/draw/:id' component={DrawGeofence} />

      <AuthRoute exact path='/monthlybill' component={MonthlyBill} />
      <AuthRoute
        exact
        path='/monthlybillonline/:orgId'
        component={MonthlyBillRedirect}
      />
      <AuthRoute
        exact
        path='/monthlybilldetailpdf/:billId'
        component={MonthlyBillPdf}
      />
      <AuthRoute exact path='/onlinepayment/cancel' component={PaymentCancel} />
      <AuthRoute exact path='/onlinepayment/fail' component={PaymentFail} />
      <AuthRoute
        exact
        path='/onlinepayment/success'
        component={PaymentSuccess}
      />

      {/* Auth Routes */}
      <LoggedInRoute exact path='/login' component={Login} />
      {/* <LoggedInRoute exact path='/signUp' component={SignUp} /> */}
      <LoggedInRoute exact path='/forget' component={ForgetPassword} />
      <LoggedInRoute exact path='/resetPassword' component={ResetPassword} />
      <LoggedInRoute exact path='/user-verify' component={UserVerify} />
      <LoggedInRoute exact path='/user/email/:id' component={Email} />
      <LoggedInRoute
        exact
        path='/password/reset/:id'
        component={ResetPassword}
      />

      {/* Public Routes */}
      <Route exact path='/mobile-stream' component={MobileStream} />
      <Route exact path='/if-login' component={IgnitionFleetLogin} />

      {/* Error Routes */}
      <Route component={NotFound} />
    </Switch>
  );
}
